import { RouterModule, Routes } from '@angular/router';

import { AccountInfoResolver } from '../account-info/account-info.resolver';
import { AuthenticatorForgottenComponent } from '../authentication-form/authenticator-forgotten/authenticator-forgotten.component';
import { AuthenticatorForgottenService } from '../authentication-form/authenticator-forgotten/authenticator-forgotten.service';
import { OtpComponent } from '../authentication-form/otp/otp.component';
import { OTPService } from '../authentication-form/otp/otp.service';
import { QrcodeComponent } from '../authentication-form/qrcode/qrcode.component';
import { QRCodeService } from '../authentication-form/qrcode/qrcode.service';
import { UserComponent } from '../authentication-form/user/user.component';
import { ValidateComponent } from '../authentication-form/validate/validate.component';
import { ValidateService } from '../authentication-form/validate/validate.service';
import { BrandingResolver } from '../branding/branding.resolver';
import { MessageSuccessfullyComponent } from '../password/message-sucessfully.component';
import { SetPasswordComponent } from '../password/set-password/set-password.component';
import { ApplicationsComponent } from '../applications/applications.component';
import { ApplicationsService } from '../applications/applications.service';
import { HardwareTokenActivationComponent } from '../self-service-portal/hardware-token-activation/hardware-token-activation.component';
import { MobileTokenActivationComponent } from '../self-service-portal/mobile-token-activation/mobile-token-activation.component';
import { SspTokenPermissionResolver } from '../self-service-portal/ssp-token-permission.resolver';
import { SspTokenComponent } from '../self-service-portal/ssp-token.component';
import { PATH } from '../shared/constants';
import { ActivationComponent } from '../token-activation/token-activation.component';
import { ActivationService } from '../token-activation/token-activation.service';
import { ForgetAuthenticatorFormComponent } from './forget-authenticator/forget-authentication-form/forget-authenticator-form.component';
import { LoginComponent } from './login.component';

const APP_ROUTES: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: PATH.PASSWORD,
        component: SetPasswordComponent,
        resolve: [BrandingResolver]
      },
      {
        path: PATH.SUCESSFULLY_MESSAGE,
        component: MessageSuccessfullyComponent
      },
      {
        path: PATH.ACTIVATION,
        component: ActivationComponent,
        resolve: {
          activation: ActivationService,
          branding: BrandingResolver
        },
      },
      {
        path: PATH.MAIN_ENDPOINT_PATH_PARAMS,
        resolve: {
          accountInfo: AccountInfoResolver,
        },
        children: [
          {
            path: PATH.SERVICE_PROVIDER_LIST,
            component: ApplicationsComponent,
            resolve: {
              applications: ApplicationsService
            }
          },
          {
            path: '',
            resolve: [BrandingResolver],
            children: [
              {
                path: PATH.SUCESSFULLY_MESSAGE,
                component: MessageSuccessfullyComponent
              },
              {
                path: PATH.OTP,
                component: OtpComponent,
                resolve: {
                  authentication: OTPService
                }
              },
              {
                path: PATH.QR_CODE,
                component: QrcodeComponent,
                resolve: {
                  authentication: QRCodeService
                }
              },
              {
                path: PATH.VALIDATE,
                component: ValidateComponent,
                resolve: {
                  authenticationPolicy: ValidateService
                }
              },
              {
                path: PATH.FORGOTTEN,
                component: AuthenticatorForgottenComponent,
                resolve: {
                  authenticationPolicy: AuthenticatorForgottenService
                }
              },
              {
                path: PATH.REQUEST_FORGOTTEN,
                component: ForgetAuthenticatorFormComponent,
                resolve: {
                  authenticationPolicy: AuthenticatorForgottenService
                }
              },
              {
                path: PATH.SSP_TOKEN,
                component: SspTokenComponent,
                canActivate: [SspTokenPermissionResolver]
              },
              {
                path: PATH.SSP_HARDWARE_TOKEN_ACTIVATION,
                component: HardwareTokenActivationComponent,
                canActivate: [SspTokenPermissionResolver]
              },
              {
                path: PATH.SSP_MOBILE_TOKEN_ACTIVATION,
                component: MobileTokenActivationComponent,
                canActivate: [SspTokenPermissionResolver]
              },
              {
                path: '',
                component: UserComponent
              },
              {
                path: '**',
                redirectTo: ''
              }
            ]
          }
        ]
      },
      {
        path: '',
        redirectTo: PATH.SP_INITIATED,
        pathMatch: 'full'
      }
    ]
  }
];

export const LoginRoute = RouterModule.forChild(APP_ROUTES);
