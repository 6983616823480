import { TransactionData } from '../authentication-form/authentication.model';
import { Injectable } from '@angular/core';

@Injectable()
export class NavigatorInformationService {
    private OS = [
        { name: 'Windows', value: 'Win' },
        { name: 'Macintosh', value: 'Mac' },
        { name: 'Linux', value: 'Linux'}
    ];
    private BROWSERS = [
        { name: 'Edge', value: 'Edge' },
        { name: 'Chrome', value: 'Chrome' },
        { name: 'Firefox', value: 'Firefox' },
        { name: 'Safari', value: 'Safari' },
        { name: 'Internet Explorer', value: 'rv:11.0' }
    ];
    private navigatorString: string;

    constructor() {
        this.navigatorString = [
            navigator.platform,
            navigator.userAgent,
            navigator.appVersion,
            navigator.vendor,
            window['opera']
        ]
        .join(' ');
    }

    getTransactionData(): TransactionData {
        return {
            osVersion: this.getOperatingSystemName(),
            browser: this.getBrowserName()
        };
    }

    getBrowserName(): string {
        return this.getNavigatorInformation(this.BROWSERS);
    }

    getOperatingSystemName(): string {
        return this.getNavigatorInformation(this.OS);
    }

    private getNavigatorInformation(data: Array<{ name: string, value: string }>): string {
        for (const value of data) {
            const regex = new RegExp(value.value, 'i');
            const match = regex.test(this.navigatorString);
            if (match) {
                return value.name;
            }
        }
    }
}
