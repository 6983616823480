import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HttpErrorNotificationModule } from '../http-error-notification/http-error-notification.module';
import { HeadersHandlerInterceptor } from '../shared/headers.interceptor';
import { SharedModule } from '../shared/shared.module';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MessageSuccessfullyComponent } from './message-sucessfully.component';
import { PasswordService } from './password-service';
import { SetPasswordComponent } from './set-password/set-password.component';


@NgModule({
  imports: [
    HttpErrorNotificationModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    ForgotPasswordComponent,
    SetPasswordComponent,
    MessageSuccessfullyComponent,
    EditPasswordComponent
  ],
  exports: [
    ForgotPasswordComponent,
    SetPasswordComponent,
    EditPasswordComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersHandlerInterceptor,
      multi: true
    },
    PasswordService
  ]
})
export class PasswordModule { }
