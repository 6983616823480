import { Attribute, Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[wgSubmitOnEnter]'
})
export class WgSubmitOnEnterDirective {

    constructor (@Attribute('wgSubmitOnEnter') private submitButtonSelector: string) {}

    @HostListener('keyup', ['$event.key'])
    keyEvent(key: string) {
        if (key && key.toLocaleLowerCase && key.toLocaleLowerCase() === 'enter') {
            const submitButton: HTMLElement = document.querySelector(this.submitButtonSelector);
            submitButton.click();
        }
    }
}
