import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Base64 } from 'js-base64';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { Authentication, AuthenticationPolicy, GeolocationData, TokenStatus } from '../../authentication-form/authentication.model';
import { BASE_URL, BASE_URL_SSP } from '../../config/app-settings';
import { BrowserService, ErrorHandlerService, PATH } from '../../shared/';
import { AuthenticationService } from '../authentication.service';
import { GeolocationService } from '../../shared/geolocation.service';

@Injectable()
export class UserService extends AuthenticationService {

  constructor(
    public errorHandleService: ErrorHandlerService,
    public loaderService: LoaderService,
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationService: GeolocationService
  ) {
    super(router, http, browserService, geolocationService);
  }

  signin(authentication: Authentication): Observable<any> {
    return this.geolocationService.getGeolocation()
      .pipe(
        map((geolocationData: GeolocationData) => {
          authentication.geolocationData = geolocationData;
          return authentication;
        }),
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/signin`, auth, { 'responseType': 'text' })),
        map(response => {
          const responseJson = response !== '' ? JSON.parse(response) : null;

          if (responseJson && responseJson.username) {
            responseJson.username = Base64.decode(responseJson.username);
          }

          return responseJson;
        })
      );
  }

  fetchAuthenticationPolicy(authentication: Authentication): Observable<AuthenticationPolicy> {
    this.loaderService.showLoading();

    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post<AuthenticationPolicy>(`${BASE_URL}/sso/authentication/type`, auth)),
        finalize(() => this.loaderService.hideLoading())
      );
  }

  fetchTokenStatusOnEnrollment(login: string, accountId: string): Observable<TokenStatus> {
    return this.fetchTokenStatus(login, accountId)
      .pipe(
        catchError(err => {
          const isInactiveForSsp = this.errorHandleService.verifiyErrorCode(['210005001', '210005002', '210005003', '210004003'], err);
          return isInactiveForSsp ? of({ hasTokens: true }) : throwError(err);
        })
      );
  }

  fetchTokenStatus(login: string, accountId: string): Observable<TokenStatus> {
    const baseUrl = BASE_URL_SSP.replace(':accountId', accountId);
    this.loaderService.showLoading();
    return this.http.post<TokenStatus>(`${baseUrl}/management/user/tokens`, { login })
      .pipe(
        finalize(() => this.loaderService.hideLoading())
      );
  }

  logout(authentication: Authentication): Observable<any> {
    this.loaderService.showLoading();
    return this.http
      .post(`${BASE_URL}/slo/logout`, authentication, { 'responseType': 'text' })
      .pipe(
        finalize(() => this.loaderService.hideLoading()),
      );
  }

  getPath(authenticationPolicy: AuthenticationPolicy): string {
    return this.isAuthenticationByOTP(authenticationPolicy) ? PATH.OTP : PATH.VALIDATE;
  }

  canNotAuthenticate(authenticationPolicy: AuthenticationPolicy): boolean {
    return (authenticationPolicy.isAuthenticationDeniedByPolicy ||
      ['otp', 'push', 'qrCode', 'password'].every((key) => !authenticationPolicy[key]));
  }

  private isAuthenticationByOTP({ otp, password, qrCode, push }: AuthenticationPolicy): boolean {
    return ((otp && password && !qrCode && !push) || (otp && !password && !qrCode && !push));
  }
}
