import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isLocalDev = window.location.href.indexOf('watchguard.com') === -1;

    // Get around the CORS policy issue when running locally.
    // This must be after setHeaders() above.
    if (isLocalDev) {
      req = this.getAroundCorsPolicy(req);
    }

    return next.handle(req);
  }

  // To get around the CORS Policy issue when running locally.
  private getAroundCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {
    if (/dev.vault-session.authpoint/.test(req.url)) {
      return req.clone({
        url: req.url.replace('https://dev.vault-session.authpoint.usa.cloud.watchguard.com', '')
      });
    }

    return req;
  }
}
