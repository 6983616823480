import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { LoaderModule } from '@watchguard/wg-loader';

import { PasswordModule } from '../password/password.module';
import { HeadersHandlerInterceptor } from '../shared/headers.interceptor';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationPolicyService } from './authentication-policy.service';
import { AuthenticatorForgottenComponent } from './authenticator-forgotten/authenticator-forgotten.component';
import { AuthenticatorForgottenService } from './authenticator-forgotten/authenticator-forgotten.service';
import { OtpComponent } from './otp/otp.component';
import { OTPService } from './otp/otp.service';
import { PendingTokenActivationComponent } from './pending-token-activation/pending-token-activation.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { QRCodeService } from './qrcode/qrcode.service';
import { UserComponent } from './user/user.component';
import { UserService } from './user/user.service';
import { ValidateComponent } from './validate/validate.component';
import { ValidateService } from './validate/validate.service';

@NgModule({
  imports: [
    PasswordModule,
    SharedModule,
    LoaderModule
  ],
  declarations: [
    UserComponent,
    OtpComponent,
    QrcodeComponent,
    ValidateComponent,
    AuthenticatorForgottenComponent,
    PendingTokenActivationComponent
  ],
  exports: [
    UserComponent,
    OtpComponent,
    QrcodeComponent,
    ValidateComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersHandlerInterceptor,
      multi: true
    },
    UserService,
    ValidateService,
    QRCodeService,
    OTPService,
    AuthenticatorForgottenService,
    AuthenticationPolicyService
  ],

})
export class AuthenticationModule { }
