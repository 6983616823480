import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { Authentication, IDP_LOGIN } from '../../authentication-form/authentication.model';
import { AutoUnsubscribe, BrowserService, ErrorHandlerService, ERROR_CODE, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, PATH } from '../../shared';
import { SspTokenService } from '../ssp-token.service';
import { HeaderService } from '../../login/header/header.service';
import { Command, HardwareTokenActivationRequest, TokenErrorParam } from '../ssp-token.model';
import { UserService } from '../../authentication-form/user/user.service';
import { hardwareSuccessResponse, tokenSerialNumberRegex } from '../ssp-token.constants';

@Component({
  selector: 'wg-ssp-hardware-token-activation',
  templateUrl: './hardware-token-activation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class HardwareTokenActivationComponent implements OnInit {

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  errorMessage: string = null;
  serialNumber: string = null;
  otp: number;
  authentication: Authentication;
  accountId: string;
  username: string;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private browserService: BrowserService,
    private errorHandlerService: ErrorHandlerService,
    private sspTokenService: SspTokenService,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (this.sspTokenService.securityContextId) {
      this.authentication = this.browserService.getItemLocalStorage(`${IDP_LOGIN}_authentication`);
      this.username = this.authentication ? this.authentication.user.username : null;
      this.accountId = this.authentication ? this.authentication.accountId : null;

      this.headerService.headerSubmit({
        title: this.messageTranslateKeys.hardware_token_activation_title,
        subtitle: this.username
      });
    } else {
      const navigationExtras: NavigationExtras = {
        relativeTo: this.activatedRoute,
        queryParams: { error: TokenErrorParam.HARDWARE_TOKEN }
      };
      this.router.navigate(['..', PATH.SSP_TOKEN], navigationExtras);
    }
  }

  activateHardwareToken() {
    forkJoin([
      this.sspTokenService.activateHardwareToken(this.accountId, this.hardwareTokenActivationRequest),
      this.userService.fetchAuthenticationPolicy(this.authentication)
    ]).subscribe(
      ([hardwareActivation, authenticationPolicy]) => {
        if (hardwareActivation && hardwareActivation.result === hardwareSuccessResponse) {
          this.errorMessage = null;

          const route = authenticationPolicy.isAuthenticationDeniedByPolicy ? ['..'] : ['..', PATH.VALIDATE];
          this.router.navigate(route, { relativeTo: this.activatedRoute });
        } else {
          this.errorHandler(ERROR_PREFIX.ssp_hardware_token_activation,
            { error: { mainMessageKey: 'auth_sso_api_error_210003020' } } as HttpErrorResponse);
        }
      },
      (error: HttpErrorResponse) => {
        const isUnauthorized = this.errorHandlerService.verifiyErrorCode([ERROR_CODE.UNAUTHORIZED_RESOURCE], error);
        if (isUnauthorized) {
          this.router.navigate(['..'], { relativeTo: this.activatedRoute });
        } else {
          this.errorHandler(ERROR_PREFIX.ssp_hardware_token_activation, error);
        }
      });
  }

  shouldFooterButtonBeDisabled() {
    const otpMaxLength = 6;
    const isValidOtp = this.otp ? this.otp.toString().length === otpMaxLength : false;
    const isValidSerial = this.serialNumber ? tokenSerialNumberRegex.test(this.serialNumber) : false;
    return !(isValidOtp && isValidSerial);
  }

  cleanErrorMessage() {
    this.errorMessage = null;
  }

  private get hardwareTokenActivationRequest(): HardwareTokenActivationRequest {
    return {
      securityContextId: this.sspTokenService.securityContextId,
      verifier: this.sspTokenService.verifier,
      friendlySerialNumber: this.serialNumber,
      otp1: this.otp.toString(),
      command: Command.ACTIVATE,
    };
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(
      requestType,
      httpErrorResponse
    );
    this.changeDetector.detectChanges();
  }

}
