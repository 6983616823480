import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from './branding/branding.service';
import { Branding } from './branding/branding.model';

@Component({
  selector: 'wg-control-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private title: Title,
    private brandingService: BrandingService
  ) {
    this.brandingService.branding.subscribe((branding: Branding) => {
      if (branding.thumbnailImageUrl) {
        document.querySelector('link[rel~="icon"]').setAttribute('href', branding.thumbnailImageUrl);
      }
    });
  }

  ngOnInit() {
    this.translateService.stream('auth_sso_login_page_title')
      .subscribe(text => this.title.setTitle(text));
  }
}
