import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { forkJoin, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { filter, finalize, first, mapTo, switchMap, tap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { AccountInfo } from '../../account-info/account-info.model';
import { AuthenticationComponent } from '../../authentication-form/authentication.component';
import { Authentication, AuthenticationPolicy, IDP_LOGIN, Saml, TokenStatus, User } from '../../authentication-form/authentication.model';
import { Branding } from '../../branding/branding.model';
import { BrandingService } from '../../branding/branding.service';
import { Header } from '../../login/header/header.model';
import { HeaderService } from '../../login/header/header.service';
import { SamlApplication } from '../../applications/applications.model';
import {
  AutoUnsubscribe,
  BrowserService,
  ERROR_CODE,
  ERROR_PREFIX,
  ErrorHandlerService,
  MESSAGE_SUCCESS,
  PATH,
  SamlFormService,
  SamlResponse,
} from '../../shared/';
import { ApplicationListService } from '../../applications/applications-list/application-list.service';
import { UserService } from './user.service';

@Component({
  selector: 'wg-control-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
@AutoUnsubscribe()
export class UserComponent extends AuthenticationComponent implements OnInit, OnDestroy {

  loaderSubscription: Subscription;
  authPolicySubscription: Subscription;
  brandingSubscription: Subscription;

  isLoading: boolean;
  header: Header;

  constructor(
    public errorHandleService: ErrorHandlerService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public samlFormService: SamlFormService,
    public browserService: BrowserService,
    public userService: UserService,
    public headerService: HeaderService,
    public applicationListService: ApplicationListService,
    private brandingService: BrandingService,
    private loaderService: LoaderService
  ) {
    super(activatedRoute, router, errorHandleService, headerService, browserService, samlFormService, applicationListService);
    this.loaderSubscription = loaderService.isLoading$
      .subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
        if (!isLoading && this.header) {
          this.headerService.headerSubmit(this.header);
        }
      });
  }

  ngOnInit() {
    this.user = new User();
    this.authentication = new Authentication();
    this.authentication.user = this.user;
    this.setCookieServiceInfo();
    this.setAuthentication();
  }

  ngOnDestroy() { /* It should be here to use the AutoUnsubscribe decorator */ }

  getAuthenticationPolicy(isValid: boolean) {
    if (isValid) {
      this.authentication.user = this.user;
      delete this.authentication.securityContext;
      this.authPolicySubscription = forkJoin([
        this.userService.fetchAuthenticationPolicy(this.authentication),
        this.serviceProvider === IDP_LOGIN ?
          this.userService.fetchTokenStatusOnEnrollment(this.user.username, this.authentication.accountId) :
          of({ hasTokens: true } as TokenStatus)
      ])
        .subscribe({
          next: ([authenticationPolicy, tokenStatus]) => {
            if (tokenStatus.hasTokens) {
              this.getAuthenticationPolicySuccesfully(authenticationPolicy);
            } else {
              let serviceProvider = IDP_LOGIN;
              if (this.serviceProvider && this.serviceProvider !== IDP_LOGIN) {
                this.browserService.saveServiceProvider(this.selectedSp);
                serviceProvider = this.serviceProvider;
              }

              if (!authenticationPolicy.isUpgrade) {
                this.clearSession();
              }

              this.browserService.saveAuthentication(serviceProvider, this.authentication);
              this.browserService.saveAuthenticationPolicy(serviceProvider, authenticationPolicy);
              this.router.navigate([PATH.SSP_TOKEN], { relativeTo: this.activatedRoute });
            }
          },
          error: (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_policy, error)
        });
    }
  }

  private getAuthenticationPolicySuccesfully(authenticationPolicy: AuthenticationPolicy) {
    if (this.userService.canNotAuthenticate(authenticationPolicy)) {
      if (this.selectedSp && this.selectedSp.isRdWeb) {
        this.browserService.setError('auth_sso_api_error_201105008');
        this.router.navigate([PATH.ERROR]);
      } else {
        this.errorMessage = 'auth_sso_api_error_201105008';
        this.loginInvalid = true;
      }
    } else {
      let serviceProvider = IDP_LOGIN;
      if (this.serviceProvider && this.serviceProvider !== IDP_LOGIN) {
        this.browserService.saveServiceProvider(this.selectedSp);
        serviceProvider = this.serviceProvider;
      }

      if (!authenticationPolicy.isUpgrade) {
        this.clearSession();
      }

      this.browserService.saveAuthenticationPolicy(serviceProvider, authenticationPolicy);
      this.browserService.saveServiceProviderLogo(serviceProvider, this.serviceProviderLogo);
      this.browserService.saveAuthentication(serviceProvider, this.authentication);

      const { queryParams: { theme } } = this.activatedRoute.snapshot;
      const navigationExtras: NavigationExtras = { relativeTo: this.activatedRoute, queryParams: { serviceProvider, theme } };
      this.router.navigate([this.userService.getPath(authenticationPolicy)], navigationExtras);
    }
  }

  private clearSession() {
    const authentication = this.browserService.getAuthenticationCookies('authentication');
    if (authentication) {
      try {
        // As the request is made while a router.navigate event happens, this call wasn't assigned to a subscription variable.
        this.userService.logout(JSON.parse(authentication)).subscribe();
      } catch (e) { }
    }

    this.browserService.deleteAuthenticationsCookies();
  }

  private setAuthentication() {
    const { queryParams, params } = this.activatedRoute.snapshot;

    if (this.isSpOrSetPasswordRoute(queryParams)) {
      this.handleQueryParams(queryParams);
    } else if (this.isIdPRoute(params)) {
      this.handleAccountAlias(params.accountAlias, queryParams.serviceProvider);
    } else {
      this.loaderService.hideLoading();
      this.router.navigate([PATH.ERROR]);
    }
  }

  private handleQueryParams(params: Params) {
    if (this.isSetPasswordRoute(params)) {
      this.router.navigate([PATH.PASSWORD, { queryParams: params, relativeTo: this.activatedRoute }]);
    } else {
      if (params.spRequestId) {
        this.authentication.saml = new Saml();
        this.authentication.saml.id = params.spRequestId;
      }
      this.authentication.accountId = params.accountId;
      if (params.isLogout === 'true') {
        this.logout();
      } else {
        this.userService.setAuthentication(this.authentication);
        this.signin();
      }
    }
  }

  private handleAccountAlias(accountAlias: string, spInitResourceName: string) {
    const accountInfo: AccountInfo = this.activatedRoute.snapshot.data.accountInfo;
    if (spInitResourceName) {
      this.authentication.saml = new Saml();
      this.authentication.saml.spInitResourceName = spInitResourceName;
    }

    this.authentication.accountId = accountInfo.accountId;
    this.browserService.setAuthenticationCookie('accountAlias', accountAlias, undefined);
    this.signin();
  }

  private logout() {
    if (this.authentication.securityContext) {
      this.loaderService.showLoading();
      this.userService
        .logout(this.authentication)
        .pipe(finalize(() => this.loaderService.hideLoading()))
        .subscribe(
          (response: any) => this.logoutSuccess(response),
          (error: HttpErrorResponse) => {
            this.errorHandle(ERROR_PREFIX.logout, error);
          }
        );
    } else {
      this.header = {
        title: this.messageTranslateKeys.user_name_or_email,
        serviceProviderLogo: this.serviceProviderLogo,
      };
      this.loaderService.hideLoading();
    }
  }

  private logoutSuccess(response: any) {
    this.browserService.deleteAuthenticationsCookies();
    delete this.authentication.user;
    delete this.authentication.saml;
    delete this.authentication.securityContext;
    if (response) {
      const samlResponse: SamlResponse = JSON.parse(response);
      if (samlResponse.assertionConsumerServiceUrl) {
        this.samlFormService.samlSubmitForm(samlResponse);
        return;
      }
    }
    this.browserService.setSuccessMessage(MESSAGE_SUCCESS.logout_success);
    this.router.navigate([PATH.SUCESSFULLY_MESSAGE]);
  }

  private signin() {
    this.isOnline().pipe(
      tap(() => this.loaderService.showLoading()),
      switchMap(() => this.userService.signin(this.authentication))
    ).subscribe(
      response => {
        this.signedSuccessfully(response);
      },
      (error: HttpErrorResponse) => {
        this.loaderService.hideLoading();

        const { serviceProvider, theme, spRequestId } = this.activatedRoute.snapshot.queryParams;
        const isRdWeb = (serviceProvider || spRequestId) && theme === 'agent';

        if (isRdWeb) {
          const errorMessage = this.errorHandleService.getMessageError('auth_sso_login_error_signin_', error);
          this.browserService.setError(errorMessage);
          this.router.navigate([PATH.ERROR], { queryParams: this.activatedRoute.snapshot.queryParams });
          return;
        }

        this.errorHandle(ERROR_PREFIX.signin, error);
        const errorsCode: string[] = [ERROR_CODE.INVALID_ACCOUNT, ERROR_CODE.UNAUTHORIZED_RESOURCE];
        if (this.authentication.saml && this.errorHandleService.verifiyErrorCode(errorsCode, error)) {
          delete this.authentication.securityContext;
          this.signin();
        }
      });
  }

  private signedSuccessfully(response: any) {
    const samlResponse = response && response.saml || response;

    if (this.authentication.securityContext) {
      this.userLogged(samlResponse);
    } else {
      this.userNotLogged(samlResponse);
      this.loaderService.hideLoading();
    }

    if (response && response.username) {
      this.authentication.user = { username: response.username };
    }

    if (response && response.saml) {
      delete response.saml;
      this.getAuthenticationPolicySuccesfully(response);
    }
  }

  private userLogged(response: any) {
    if (this.hasAssertionConsumerServiceUrl(response)) {
      this.loginSp(response);
    } else if (this.isUpgradeOrForgotAuthenticator(response)) {
      if (response.saml) {
        this.setSamlAuthenticator(response.saml);
        delete response.saml;
      }
      this.getAuthenticationPolicySuccesfully(response);
      this.loaderService.hideLoading();
    } else if (this.hasSamlRequest(response)) {
      this.setSamlAuthenticator(response);
      delete response.saml;
      this.loaderService.hideLoading();
    } else if (response) {
      this.loginIdp(response);
      this.loaderService.hideLoading();
      this.router.navigate([PATH.SERVICE_PROVIDER_LIST], { relativeTo: this.activatedRoute });
    } else {
      this.loaderService.hideLoading();
    }
  }

  private hasSamlRequest(response: any) {
    // Verify the The security context was expired
    return response && response.samlRequest;
  }

  private isUpgradeOrForgotAuthenticator(response: any) {
    // It is need to upgrade session or forgot token is activated
    return response && (response.isUpgrade || response.isAuthenticatorForgotten);
  }

  private hasAssertionConsumerServiceUrl(response: any) {
    // The security context is active and login by Service provider.
    return response && response.assertionConsumerServiceUrl;
  }

  private userNotLogged(response: any) {
    if (response) {
      this.setSamlAuthenticator(response);
    } else {
      this.serviceProvider = IDP_LOGIN;
    }
  }

  private setSamlAuthenticator(response: any) {
    const { accountId } = this.activatedRoute.snapshot.queryParams;

    this.selectedSp = new SamlApplication(JSON.parse(response.selectedSp));
    this.serviceProvider = this.selectedSp.name;

    if (this.selectedSp.isTypeOthers && !this.selectedSp.hasImage) {
      this.brandingService.loadBranding(accountId);
      this.brandingSubscription = this.brandingService.branding.subscribe((branding: Branding) => {
        this.setSamlHeader(branding.smallImageUrl);
      });
    } else {
      this.setSamlHeader(this.selectedSp.getImage(accountId));
    }

    this.authentication.saml = { relayState: response.relayState, samlRequest: response.samlRequest };
  }

  private setSamlHeader(serviceProviderLogo: string) {
    this.serviceProviderLogo = serviceProviderLogo;
    this.header = {
      title: this.messageTranslateKeys.user_name_or_email,
      serviceProviderLogo: this.serviceProviderLogo
    };
    this.headerService.headerSubmit(this.header);
  }

  private setCookieServiceInfo() {
    const cookieAuthenticationString = this.browserService.getAuthenticationCookies('authentication');
    if (cookieAuthenticationString) {
      this.authentication = JSON.parse(cookieAuthenticationString);
    }
  }

  private isSpOrSetPasswordRoute(queryParams: Params): boolean {
    return Object.keys(queryParams).length !== 0 && !queryParams.serviceProvider;
  }

  private isIdPRoute(params: Params): boolean {
    return params.accountAlias && params.accountAlias !== PATH.SP_INITIATED;
  }

  private isSetPasswordRoute(params: Params): boolean {
    return params.userId;
  }

  private isOnline(): Observable<boolean> {
    this.header = {
      title: this.messageTranslateKeys.user_name_or_email
    };
    return merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    ).pipe(
      tap(isOnline => !isOnline && this.headerService.headerSubmit(this.header)),
      filter(isOnLine => isOnLine),
      first()
    );
  }
}
