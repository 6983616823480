import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { Authentication, AuthenticationPolicy, OTP, PUSH, QR_CODE, SessionIntegration } from '../authentication-form/authentication.model';
import { SamlApplication } from '../applications/applications.model';
import { SAML_APPLICATION_TYPE } from './constants';
import { MESSAGE_TRANSLATE_KEYS } from './ui-strings';

@Injectable()
export class BrowserService {

  private loginString = MESSAGE_TRANSLATE_KEYS;
  private localStorage: { [name: string]: any } = {};

  constructor(private cookieService: CookieService) { }

  saveAuthenticationPolicy(serviceProviderName: string, authenticationPolicy: AuthenticationPolicy) {
    this.setItemLocalStorage(`${serviceProviderName}_authenticationPolicy`, authenticationPolicy);
    this.setItemLocalStorage(`${serviceProviderName}_authenticationPolicyLabels`, this.getAuthenticationPolicyLabels(authenticationPolicy));
  }

  clearLocalStorage(serviceProviderName: string) {
    this.deleteItemLocalStorage(`${serviceProviderName}_authentication`);
    this.deleteItemLocalStorage(`${serviceProviderName}_serviceProviderLogo`);
    this.deleteItemLocalStorage(`${serviceProviderName}_authenticationPolicy`);
    this.deleteItemLocalStorage(`${serviceProviderName}_authenticationPolicyLabels`);
  }

  saveAuthentication(serviceProviderName: string, authentication: Authentication) {
    this.setItemLocalStorage(`${serviceProviderName}_authentication`, authentication);
  }

  saveServiceProviderLogo(serviceProviderName: string, serviceProviderLogo: string) {
    this.setItemLocalStorage(`${serviceProviderName}_serviceProviderLogo`, { serviceProviderLogo });
  }

  saveServiceProvider(serviceProvider: SamlApplication) {
    this.setItemLocalStorage(`${serviceProvider.name}_serviceProvider`, serviceProvider);
  }

  setError(error: string) {
    this.setItemLocalStorage('error', { error });
  }

  setSuccessMessage(message: string) {
    this.setItemLocalStorage('success', { message });
  }

  setAuthenticationCookie(authenticationKey: string, item: string, serviceProvider: SamlApplication, expiresDate?: number | Date) {
    if (serviceProvider && serviceProvider.samlApplicationType === SAML_APPLICATION_TYPE.RD_WEB) {
      this.cookieService.set(authenticationKey, item, expiresDate, '/', undefined, true, 'None');
    } else {
      this.cookieService.set(authenticationKey, item, { expires: expiresDate, path: '/', secure: true });
    }
  }

  setSessionIntegrationCookie (sessionIntegration: SessionIntegration, expiresDate?: number | Date) {
    this.cookieService.set('sessionIntegration', JSON.stringify(sessionIntegration), { expires: expiresDate, path: '/', secure: true });
  }

  getAuthenticationCookies(authenticationKey: string): string {
    return this.cookieService.get(authenticationKey);
  }

  deleteAuthenticationsCookies() {
    this.cookieService.delete('authentication', '/');
    this.cookieService.delete('serviceProviderList', '/');
    this.cookieService.delete('isAuthenticationValid', '/');
    this.cookieService.delete('sessionIntegration', '/');
  }

  deleteCookies() {
    this.cookieService.deleteAll('/');
  }

  getItemLocalStorage(key: string): any {
    if (this.localStorage[key]) {
      return this.localStorage[key];
    } else if (localStorage) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  }

  deleteItemLocalStorage(key: string) {
    delete this.localStorage[key];
    if (localStorage) {
      localStorage.removeItem(key);
    }
  }

  setItemLocalStorage(key: string, item: object) {
    this.localStorage[key] = item;
    if (localStorage) {
      localStorage.setItem(key, JSON.stringify(item));
    }
  }

  private getAuthenticationPolicyLabels({ push, qrCode, otp }: AuthenticationPolicy): Array<object> {
    const authentications = new Array<object>();
    if (push) {
      authentications.push({
        label: this.loginString.send_push,
        name: PUSH
      });
    }
    if (qrCode) {
      authentications.push({
        label: this.loginString.send_qrcode,
        name: QR_CODE
      });
    }
    if (otp) {
      authentications.push({
        label: this.loginString.send_otp,
        name: OTP
      });
    }
    return authentications;
  }
}
