import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpErrorNotificationService } from './http-error-notification.service';

@Injectable()
export class HttpErrorNotificationInterceptor implements HttpInterceptor {

  constructor(
    @Inject(forwardRef(() => HttpErrorNotificationService))
    private httpErrorNotificationService: HttpErrorNotificationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next
      .handle(request)
      .pipe(
        tap(() => this.httpErrorNotificationService.newRequest(request)),
        catchError(this.notifyError.bind(this))
      );
  }

  private notifyError(error?: HttpErrorResponse) {
    this.httpErrorNotificationService.emitError(error);
    return throwError(error);
  }
}
