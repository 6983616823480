import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AutoUnsubscribe, MESSAGE_TRANSLATE_KEYS } from '../../shared';

@Component({
  selector: 'wg-ssp-hardware-token-form',
  templateUrl: './hardware-token-form.component.html'
})
@AutoUnsubscribe()
export class HardwareTokenFormComponent {

  @Input() serialNumber: string;
  @Input() otp: number;
  @Output() serialNumberChange = new EventEmitter<string>();
  @Output() otpChange = new EventEmitter<number>();

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

}
