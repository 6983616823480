import { SecurityContext } from '../authentication-form/authentication.model';
import { BRANCH, REGION } from '../config/app-settings';
import { SAML_APPLICATION_TYPE, DEFAULT_IMAGE } from '../shared';

export interface CorporateApplicationsRequest {
  securityContextId: string;
  username: string;
}

export interface CorporateApplicationsResponse {
  corporates: CorporateApplicationResponse[];
}

export interface CorporateApplicationResponse {
  name: string;
  url: string;
  imageId?: string;
}

export interface SamlApplicationsResponse {
  securityContext?: SecurityContext;
  serviceProviderList?: SamlApplicationResponse[];
}

export interface SamlApplicationResponse {
  resourceName: string;
  applicationType: string;
  imageId?: string;
}

export class Application {
  name: string;
  type: ApplicationType;
  url: string;
  imageId?: string;

  constructor(init: Application) {
    Object.assign(this, init);
  }
}

export class SamlApplication extends Application {
  samlApplicationType?: string;

  constructor(init: SamlApplicationResponse) {
    const application = {
      name: init.resourceName,
      type: ApplicationType.SAML,
      url: `saml/?serviceProvider=${encodeURIComponent(init.resourceName)}&applicationType=${init.applicationType}`,
      samlApplicationType: init.applicationType
    } as SamlApplication;

    if (init.imageId) {
      application.imageId = init.imageId;
      application.url += `&applicationLogoId=${init.imageId}`;
    }

    super(application);
  }

  getImage(accountId: string, defaultImage: string = DEFAULT_IMAGE.HEADER): string {
    if (this.isRdWeb) {
      return null;
    }

    if (this.isTypeOthers) {
      return (this.hasImage && accountId)
      ? `https://${BRANCH}${REGION}.saml.images.authpoint.watchguard.com/${accountId}/${this.imageId}.png`
      : defaultImage;
    } else {
      return `assets/logos/${this.samlApplicationType}.png`;
    }
  }

  get isTypeOthers(): boolean {
    return this.samlApplicationType === SAML_APPLICATION_TYPE.OTHERS;
  }

  get isRdWeb(): boolean {
    return this.samlApplicationType === SAML_APPLICATION_TYPE.RD_WEB;
  }

  get hasImage(): boolean {
    return Boolean(this.imageId);
  }
}

export class CorporateApplication extends Application {
  constructor(init: CorporateApplicationResponse) {
    const application = {
      ...init,
      type: ApplicationType.CORPORATE_APPLICATION
    } as CorporateApplication;

    const urlRegex = new RegExp(/^\/*/);
    if (urlRegex.test(application.url)) {
      application.url = application.url.replace(urlRegex, '');
    }

    if (!(application.url.startsWith('http://') || application.url.startsWith('https://'))) {
      application.url = `https://${application.url}`;
    }

    super(application);
  }

  getImage(accountId: string, defaultImage: string = DEFAULT_IMAGE.HEADER): string {
    return (this.imageId && accountId)
      ? `https://${BRANCH}${REGION}.vault.corporate.images.authpoint.watchguard.com/${accountId}/${this.imageId}.png`
      : defaultImage;
  }
}

export enum ApplicationType {
  SAML = 'saml',
  CORPORATE_APPLICATION = 'corporateApplication'
}
