import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { BrowserService } from '../../shared/browser.service';
import { GeolocationService } from '../../shared/geolocation.service';

@Injectable()
export class OTPService extends AuthenticationService {

  private password: string;

  constructor(
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationSerice: GeolocationService
  ) {
    super(router, http, browserService, geolocationSerice);
  }

  getPassword(): string {
    return this.password;
  }

  setPassword(password: string) {
    this.password = password;
  }
}
