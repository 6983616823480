import { Component } from '@angular/core';
import { Location } from '@angular/common';

import { MESSAGE_TRANSLATE_KEYS } from '../../shared/ui-strings';

@Component({
  selector: 'wg-back-page',
  templateUrl: './back-page.component.html'
})
export class BackPageComponent {
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  constructor(private location: Location) { }

  back() {
    this.location.back();
  }
}
