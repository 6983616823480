import { Component } from '@angular/core';

import { MESSAGE_TRANSLATE_KEYS } from '../shared';
import { fadeAnimation } from '../shared/animations';
import { HttpErrorNotificationService } from './http-error-notification.service';

@Component({
  selector: 'wg-http-error-notification',
  templateUrl: './http-error-notification.component.html',
  styleUrls: ['./http-error-notification.component.scss'],
  animations: [fadeAnimation]
})
export class HttpErrorNotificationComponent {

  httpError = this.httpErrorNotificationService.getError();
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  constructor(
    private httpErrorNotificationService: HttpErrorNotificationService
  ) { }

}
