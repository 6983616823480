import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BASE_URL } from '../../config/app-settings';
import { ForgottenAuthenticatorActivationRequest } from '../../models';
import { Authentication } from '../../authentication-form/authentication.model';
import { GeolocationService } from '../../shared/geolocation.service';

@Injectable()
export class ForgetAuthenticatorService {

  constructor(
    private http: HttpClient,
    private geolocationService: GeolocationService
  ) { }

  validateAuthenticationCode(forgottenAuthenticatorActivationRequest: ForgottenAuthenticatorActivationRequest): Observable<any> {
    return this.http.post(`${BASE_URL}/sso/authentication/authenticator/status/forgotten`, forgottenAuthenticatorActivationRequest);
  }

  loginMFAByPass(authentication: Authentication, isUpgrade?: boolean): Observable<any> {
    let observable: Observable<any>;
    if (authentication.saml && authentication.saml.selectedSp) {
      observable = isUpgrade ? this.authenticateUpgrade(authentication) : this.authenticateLoginMFAByPass(authentication);
    } else {
      observable = this.authenticate(authentication);
    }
    return observable;
  }

  private authenticate(authentication: Authentication) {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/authentication`, auth))
      );
  }

  private authenticateLoginMFAByPass(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authentication`, auth))
      );
  }

  private authenticateUpgrade(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authenticationUpgrade`, auth))
      );
  }
}
