import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, race, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take } from 'rxjs/operators';

import {
  Application,
  CorporateApplicationResponse,
  CorporateApplication,
  CorporateApplicationsRequest,
  CorporateApplicationsResponse,
  SamlApplicationResponse,
  SamlApplication,
  SamlApplicationsResponse
} from '../applications.model';
import { Authentication, AuthenticationPolicy, GeolocationData } from '../../authentication-form/authentication.model';
import { BASE_URL, BASE_URL_VAULT_SESSION } from '../../config/app-settings';
import { BrowserService } from '../../shared/browser.service';
import { GeolocationService } from '../../shared/geolocation.service';
import { SessionService } from '../../session/session.service';

@Injectable()
export class ApplicationListService {
  private samlApplicationsSubject = new ReplaySubject<Application[]>(1);

  private authentication: Authentication;

  constructor(
    private http: HttpClient,
    private browserService: BrowserService,
    private geolocationService: GeolocationService,
    private sessionService: SessionService
  ) { }

  setAuthentication(authentication: Authentication) {
    this.authentication = authentication;
  }

  setSamlApplications(samlApplications: SamlApplicationResponse[]) {
    const parsedSamlApplications = this.parseSamlApplications(samlApplications);
    this.samlApplicationsSubject.next(parsedSamlApplications);
  }

  getApplications(): Observable<Application[]> {
    return forkJoin([this.getSamlApplications(), this.getCorporateApplications()])
      .pipe(
        map(([samlApplications, corporateApplications]) => samlApplications.concat(corporateApplications))
      );
  }

  getSamlApplications(): Observable<Application[]> {
    return race(
      this.samlApplicationsSubject.asObservable(),
      this.getSamlApplicationsBySignin()
    ).pipe(
      take(1)
    );
  }

  getSamlApplicationsBySignin(): Observable<Application[]> {
    const authentication = this.getAuthentication();
    const baseUrl = `${BASE_URL}/sso/signin`;

    return this.geolocationService.getGeolocation()
      .pipe(
        map((geolocationData: GeolocationData) => {
          if (authentication) {
            authentication.geolocationData = geolocationData;
          }
          return authentication;
        }),
        switchMap((auth: Authentication) => this.http.post<SamlApplicationsResponse | AuthenticationPolicy>(baseUrl, auth, { responseType: 'json' })),
        map((response: SamlApplicationsResponse | AuthenticationPolicy) => this.handleSigninResponse(response)),
        catchError(() => of([]))
      );
  }

  getCorporateApplications(): Observable<Application[]> {
    const authentication = this.getAuthentication();
    const baseUrl = BASE_URL_VAULT_SESSION.replace(':accountId', authentication?.accountId);
    const body: CorporateApplicationsRequest = {
      securityContextId: authentication?.securityContext?.id,
      username: authentication?.user?.username
    };

    return this.http.post<CorporateApplicationsResponse>(baseUrl, body)
      .pipe(
        map((response: CorporateApplicationsResponse) => this.parseCorporateApplications(response?.corporates)),
        catchError(() => of([]))
      );
  }

  parseSamlApplications(samlApplications: SamlApplicationResponse[]): Application[] {
    return samlApplications
      ? samlApplications.map((samlApplication: SamlApplicationResponse) => new SamlApplication(samlApplication))
      : [];
  }

  parseCorporateApplications(corporateApplications: CorporateApplicationResponse[]): Application[] {
    return corporateApplications
      ? corporateApplications.map((corporateApplication: CorporateApplicationResponse) => new CorporateApplication(corporateApplication))
      : [];
  }

  handleSigninResponse(response: (SamlApplicationsResponse | AuthenticationPolicy)): Application[] {
    if (this.sessionService.hasToUpgradeSession(response as AuthenticationPolicy)) {
      this.sessionService.upgradeSession(this.getAuthentication(), response as AuthenticationPolicy);
    }

    return this.parseSamlApplications((response as SamlApplicationsResponse)?.serviceProviderList);
  }

  get applications(): Observable<Application[]> {
    return this.getApplications()
      .pipe(
        map((applications: Application[]) => applications.sort(this.sortApplicationsByName)),
        shareReplay(1)
      );
  }

  private getAuthentication(): Authentication {
    if (this.authentication) {
      return this.authentication;
    }

    const authenticationCookie = this.browserService.getAuthenticationCookies('authentication');
    let authentication = null;

    if (authenticationCookie) {
      authentication = JSON.parse(authenticationCookie);
      this.authentication = authentication;
    }

    return authentication;
  }

  private sortApplicationsByName(applicationA: Application, applicationB: Application): number {
    if (applicationA.name.toLowerCase() < applicationB.name.toLowerCase()) {
      return -1;
    }
    if (applicationA.name.toLowerCase() > applicationB.name.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}
