import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { MobileTokenActivationComponent } from './mobile-token-activation/mobile-token-activation.component';
import { QrCodeActivationComponent } from './qrcode-activation/qrcode-activation.component';
import { SspTokenComponent } from './ssp-token.component';
import { SspTokenService } from './ssp-token.service';
import { PasswordAuthenticationComponent } from './password-authentication/password-authentication.component';
import { MobileTokenActivationDialogComponent } from './mobile-token-activation-dialog/mobile-token-activation-dialog.component';
import { HardwareTokenActivationComponent } from './hardware-token-activation/hardware-token-activation.component';
import { HardwareTokenFormComponent } from './hardware-token-form/hardware-token-form.component';
import { HardwareTokenActivationDialogComponent } from './hardware-token-activation-dialog/hardware-token-activation-dialog.component';
import { HttpErrorNotificationModule } from '../http-error-notification/http-error-notification.module';
@NgModule({
  imports: [
    HttpErrorNotificationModule,
    SharedModule
  ],
  declarations: [
    HardwareTokenActivationComponent,
    HardwareTokenActivationDialogComponent,
    HardwareTokenFormComponent,
    MobileTokenActivationComponent,
    MobileTokenActivationDialogComponent,
    PasswordAuthenticationComponent,
    QrCodeActivationComponent,
    SspTokenComponent
  ],
  exports: [
    HardwareTokenActivationComponent,
    HardwareTokenActivationDialogComponent,
    MobileTokenActivationComponent,
    MobileTokenActivationDialogComponent
  ],
  providers: [
    SspTokenService
  ]
})
export class SspTokenModule { }
