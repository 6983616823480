import { MESSAGE_TRANSLATE_KEYS } from '../shared';
import { TokenErrorParam } from './ssp-token.model';

export const ErrorMessages = {
    [TokenErrorParam.HARDWARE_TOKEN]: MESSAGE_TRANSLATE_KEYS.ssp_token_error_initializing_hardware_token_page,
    [TokenErrorParam.QRCODE]: MESSAGE_TRANSLATE_KEYS.ssp_token_error_generating_qrcode
};

export const hardwareSuccessResponse = 'SUCCESS';

export const tokenSerialNumberRegex = /^(?!\s)(?!.*\s$).+$/;
