import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IDP_LOGIN } from '../authentication-form/authentication.model';

import { HeaderService } from '../login/header/header.service';
import { DEFAULT_IMAGE, PATH } from '../shared';
import { BrowserService } from '../shared/browser.service';

@Component({
  selector: 'wg-message-sucessfully',
  template: `<h2 [translate]="message"></h2>`,
})
export class MessageSuccessfullyComponent implements OnInit {
  message: string;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private browserService: BrowserService
  ) { }

  ngOnInit() {
    const result = this.browserService.getItemLocalStorage('success');
    if (result) {
      this.message = result.message;
      this.setServiceProviderLogo(this.activatedRoute.snapshot.queryParams);
    } else {
      this.router.navigate([PATH.ERROR]);
    }
  }

  private setServiceProviderLogo(queryParams: Params) {
    const serviceProviderKey: string = queryParams.serviceProvider ? queryParams.serviceProvider : IDP_LOGIN;
    const serviceProvider = this.browserService.getItemLocalStorage(`${serviceProviderKey}_serviceProviderLogo`);
    const logo = serviceProvider ? serviceProvider.serviceProviderLogo : DEFAULT_IMAGE.HEADER;

    this.headerService.headerSubmit({ serviceProviderLogo: logo });
  }

}
