import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BrowserService, DEFAULT_IMAGE, MESSAGE_TRANSLATE_KEYS } from '../shared/';

@Component({
  selector: 'wg-login-error-validate',
  templateUrl: './error-validate.component.html',
  styleUrls: ['./error-validate.component.scss']
})
export class ErrorValidateComponent implements OnInit {

  messageTranslateKeys: string;
  errorImage = DEFAULT_IMAGE.ERROR;
  isRdWeb = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private browserService: BrowserService
  ) { }

  ngOnInit(): void {
    const { serviceProvider, theme, spRequestId } = this.activatedRoute.snapshot.queryParams;
    this.isRdWeb = (serviceProvider || spRequestId) && theme === 'agent';

    const error = this.browserService.getItemLocalStorage('error');
    if (error && error.error) {
      this.browserService.deleteItemLocalStorage('error');
      this.messageTranslateKeys = error.error;
    } else {
      this.messageTranslateKeys = MESSAGE_TRANSLATE_KEYS.invalid_customer_name;
    }
  }

  retry() {
    parent.postMessage('retry', '*');
  }

  logout() {
    parent.postMessage('logout', '*');
  }
}
