import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';
import { BASE_URL } from '../../config/app-settings';
import { BrowserService } from '../../shared/browser.service';
import { Authentication } from '../../authentication-form/authentication.model';
import { GeolocationService } from '../../shared/geolocation.service';

@Injectable()
export class QRCodeService extends AuthenticationService {

  private password: string;

  constructor(
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationService: GeolocationService) {
    super(router, http, browserService, geolocationService);
  }

  getPassword() {
    return this.password;
  }

  setPassword(password: string) {
    this.password = password;
  }

  qrcodeResponse(authentication: Authentication, isUpgrade?: boolean): Observable<any> {
    let observable: Observable<any>;
    if (authentication.saml && authentication.saml.selectedSp) {
      observable = isUpgrade ? this.qrcodeAuthenticateUpgrade(authentication) : this.qrcodeAuthenticateMFAByPass(authentication);
    } else {
      observable = this.qrcodeResponseAuthenticate(authentication);
    }
    return observable;
  }

  private qrcodeResponseAuthenticate(authentication: Authentication) {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/authentication/qrcode/response`, auth))
      );
  }

  private qrcodeAuthenticateUpgrade(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authenticationUpgrade/qrcode/response`, auth))
      );
  }

  private qrcodeAuthenticateMFAByPass(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authentication/qrcode/response`, auth))
      );
  }
}
