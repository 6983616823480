import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WgIconModule } from '@watchguard/wg-icon';
import { LoaderModule } from '@watchguard/wg-loader';

import { HttpErrorNotificationModule } from './../http-error-notification/http-error-notification.module';
import { ErrorValidateComponent } from './error-validate.component';
import { ErrorValidateRoute } from './error.validate.route';

@NgModule({
  imports: [
    FormsModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    LoaderModule,
    ErrorValidateRoute,
    WgIconModule,
    HttpErrorNotificationModule
  ],
  declarations: [
    ErrorValidateComponent
  ],
  exports: [
    ErrorValidateComponent
  ]
})
export class ErrorValidateModule { }
