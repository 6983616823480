import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { WgOnlyNumberModule } from '@watchguard/wg-directives';
import { LoaderModule } from '@watchguard/wg-loader';
import { WgNotificationsModule, WgNotificationsService } from '@watchguard/wg-notifications';
import { ValidatorModule } from '@watchguard/wg-validator';

import { AccountInfoResolver } from './account-info/account-info.resolver';
import { AppRoutingModule } from './app-route.module';
import { AppComponent } from './app.component';
import { ErrorValidateModule } from './error-validate/error-validate.module';
import { InternetMonitoringComponent } from './internet-monitoring/internet-monitoring.component';
import { LocaleModule } from './locale/locale.module';
import { LoginModule } from './login/login.module';
import { PasswordModule } from './password/password.module';
import { ApplicationsModule } from './applications/applications.module';
import { SPAuthenticationModule } from './service-provider-authentication/sp-authentication.module';
import { ErrorHandlerInterceptor } from './shared/error-handler.interceptor';
import { HeadersHandlerInterceptor } from './shared/headers.interceptor';
import { ApplicationListService } from './applications/applications-list/application-list.service';
import { SharedModule } from './shared/shared.module';
import { ActivationModule } from './token-activation/token-activation.module';
import { BrandingResolver } from './branding/branding.resolver';
import { AuthInterceptor } from './shared/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    InternetMonitoringComponent
  ],
  imports: [
    WgOnlyNumberModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SPAuthenticationModule,
    ApplicationsModule,
    ErrorValidateModule,
    LoginModule,
    SharedModule,
    PasswordModule,
    ActivationModule,
    ValidatorModule,
    WgNotificationsModule,
    LocaleModule,
    LoaderModule.forRoot(),
  ],
  providers: [
    AccountInfoResolver,
    ApplicationListService,
    BrandingResolver,
    WgNotificationsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
