import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SessionService } from '../session/session.service';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    SessionService
  ]
})
export class SessionModule { }
