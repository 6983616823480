import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { IDP_LOGIN } from '../../authentication-form/authentication.model';
import { BrowserService } from '../../shared';

import { Header } from './header.model';

@Injectable()
export class HeaderService {
  header: Subject<Header> = new Subject<Header>();

  constructor(
    private browserService: BrowserService
  ) { }

  headerSubmit(header: Header) {
    if (header.serviceProviderLogo) {
      this.browserService.saveServiceProviderLogo(IDP_LOGIN, header.serviceProviderLogo);
    }
    this.header.next(header);
  }
}
