import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface SamlResponse {
    samlResponse: string;
    relayState: string;
    logoutResponse: string;
    assertionConsumerServiceUrl: string;
    isIdpInitiated: boolean;
}

@Injectable()
export class SamlFormService {
    samlSubmit: Subject<SamlResponse>;

    constructor() {
        this.samlSubmit = new Subject<SamlResponse>();
    }

    samlSubmitForm(samlResponse: SamlResponse) {
        this.samlSubmit.next(samlResponse);
    }
}
