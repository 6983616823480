import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Authentication, AuthenticationPolicy } from '../../authentication-form/authentication.model';
import { AutoUnsubscribe, MESSAGE_TRANSLATE_KEYS, PATH } from '../../shared';

@Component({
  selector: 'wg-forget-authenticator',
  templateUrl: './forget-authenticator.component.html',
  styleUrls: ['./forget-authenticator.component.scss']
})
@AutoUnsubscribe()
export class ForgetAuthenticatorComponent {
  private resourceName: string;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  @Input() authenticationPolicy: AuthenticationPolicy;
  @Input() authentication: Authentication;
  @Input() allowExtendTime = false;
  @Output() loginResponse = new EventEmitter();

  isForgotTokenDialogOpen: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.resourceName = this.activatedRoute.snapshot.queryParams.serviceProvider;
  }

  openForgetAuthenticatorDialog() {
    if (this.authenticationPolicy && this.authenticationPolicy.isAuthenticatorForgotten && !this.allowExtendTime) {
      this.navigateToAuthenticatorForgottenPage();
    } else {
      this.isForgotTokenDialogOpen = true;
    }
  }

  closeForgotAuthenticatorDialog() {
    this.isForgotTokenDialogOpen = false;
  }

  isShowLinkForgetAuthentication(): boolean {
    if (this.authenticationPolicy) {
      const { otp, qrCode, push } = this.authenticationPolicy;
      return (otp || qrCode || push);
    }
    return false;
  }

  private navigateToAuthenticatorForgottenPage() {
    this.router.navigate(['..', PATH.FORGOTTEN], {
      replaceUrl: true,
      relativeTo: this.activatedRoute,
      queryParams: { serviceProvider: this.resourceName }
    });
  }
}
