import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from '../shared/shared.module';
import { HttpErrorNotificationComponent } from './http-error-notification.component';
import { HttpErrorNotificationInterceptor } from './http-error-notification.interceptor';

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule
  ],
  declarations: [
    HttpErrorNotificationComponent
  ],
  exports: [
    HttpErrorNotificationComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorNotificationInterceptor,
      multi: true
    }
  ]
})
export class HttpErrorNotificationModule { }
