import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { finalize, mergeMap, switchMap, tap } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader';

import { BASE_URL } from '../config/app-settings';
import { BrowserService } from '../shared/browser.service';
import { ERROR_PREFIX } from '../shared/ui-strings';
import { Authentication, AuthenticationPolicy } from './authentication.model';
import { GeolocationService } from '../shared/geolocation.service';

@Injectable()
export class AuthenticationPolicyService {

  constructor(
    public loaderService: LoaderService,
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationService: GeolocationService
  ) { }

  fetchAuthenticationPolicy(authentication: Authentication, serviceProvider: string): Observable<AuthenticationPolicy> {

    const handleError = authenticationPolicy => {
      if (Object.keys(authenticationPolicy).every((key) => !authenticationPolicy[key])) {
        const customError = new HttpErrorResponse({ error: { mainMessageKey: ERROR_PREFIX.authentication_policy + '0' } });
        return throwError(customError);
      }
      return of(authenticationPolicy);
    };


    this.loaderService.showLoading();
    return this.geolocationService.validateGeolocation(authentication)
      .pipe(
        switchMap((auth: Authentication) => this.http.post<AuthenticationPolicy>(`${BASE_URL}/sso/authentication/type`, auth)),
        mergeMap(handleError),
        tap(authenticationPolicy => this.browserService.saveAuthenticationPolicy(serviceProvider, authenticationPolicy)),
        finalize(() => this.loaderService.hideLoading())
      );
  }
}
