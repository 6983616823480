import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

const availableLanguages = {
  'en': 'en',
  'de': 'de',
  'es': 'es-es',
  'es-es': 'es-es',
  'es-ar': 'es-419',
  'es-bo': 'es-419',
  'es-cl': 'es-419',
  'es-co': 'es-419',
  'es-cr': 'es-419',
  'es-do': 'es-419',
  'es-ec': 'es-419',
  'es-sv': 'es-419',
  'es-gt': 'es-419',
  'es-hn': 'es-419',
  'es-mx': 'es-419',
  'es-ni': 'es-419',
  'es-pa': 'es-419',
  'es-py': 'es-419',
  'es-pe': 'es-419',
  'es-pr': 'es-419',
  'es-uy': 'es-419',
  'es-ve': 'es-419',
  'es-us': 'es-419',
  'es-419': 'es-419',
  'fr': 'fr',
  'it': 'it',
  'ja': 'ja',
  'ko': 'ko',
  'nl': 'nl',
  'pt': 'pt-pt',
  'pt-br': 'pt-br',
  'pt-pt': 'pt-pt',
  'th': 'th',
  'zh': 'zh-tw',
  'zh-tw': 'zh-tw',
  'zh-hk': 'zh-tw',
  'zh-mo': 'zh-tw',
  'zh-cn': 'zh-cn',
  'zh-sg': 'zh-cn',
};

declare const CHECKSUM: { [lang: string]: string };

export const defaultLanguage = 'en';

export function setCurrentLanguage(translate: TranslateService): Observable<any> {

  const browserCultureLang = translate.getBrowserCultureLang().toLowerCase();
  const browserLang = translate.getBrowserLang().toLowerCase();

  if (availableLanguages[browserCultureLang]) {
    return translate.use(availableLanguages[browserCultureLang]);
  } else if (availableLanguages[browserLang]) {
    return translate.use(availableLanguages[browserLang]);
  } else {
    return translate.use(defaultLanguage);
  }
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<null>(resolve => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang(defaultLanguage);
      setCurrentLanguage(translate)
        .subscribe({
          complete: () => {
            resolve(null);
            window.onlanguagechange = () => setCurrentLanguage(translate);
          }
        });
    });
  });
}

export class CustomTranslateLoader implements TranslateLoader {

  constructor(
    private httpClient: HttpClient
  ) { }

  getTranslation(lang: string): Observable<any> {

    let checksum: string;
    let defaultChecksum: string;

    if (typeof CHECKSUM !== 'undefined') {
      checksum = CHECKSUM[lang.toLowerCase()];
      defaultChecksum = CHECKSUM['en'];
    } else {
      checksum = new Date().getTime().toString();
      defaultChecksum = new Date().getTime().toString();
    }

    return this.httpClient.get(`assets/i18n/${lang.toLowerCase()}.json?v=${checksum}`)
      .pipe(
        catchError(() => this.httpClient.get(`assets/i18n/en.json?v=${defaultChecksum}`)),
        retry(3)
      );
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ]
})
export class LocaleModule { }
