export interface AuthenticateUserPasswordRequest {
  login: string;
  password: string;
  challenge: string;
}

export interface HardwareTokenActivationRequest {
  securityContextId: string;
  verifier: string;
  friendlySerialNumber: string;
  otp1: string;
  otp2?: string;
  command: Command;
}

export interface MobileTokenActivationRequest {
  securityContextId: string;
  verifier: string;
  serialNumber?: string;
  command: Command;
}

export interface AuthenticateUserPasswordResponse {
  creationDate: number;
  securityContextId: string;
}

export interface HardwareTokenActivationResponse {
  result: string;
}

export interface MobileTokenActivationResponse {
  qrCodeImage: string;
  mobileActivationLink: string;
}

export interface SspToggleFeatureResponse {
  preference: boolean;
}

export enum Command {
  ACTIVATE = 'ACTIVATE',
  DISASSOCIATE = 'DISASSOCIATE',
  BLOCK = 'BLOCK',
  UNBLOCK = 'UNBLOCK',
  DELETE = 'DELETE'
}

export enum TokenErrorParam {
  HARDWARE_TOKEN = 'hardware-token',
  QRCODE = 'qrcode',
}

export enum AuthMethod {
  ACTIVATE_HARDWARE_TOKEN = 'activateHardwareToken',
  ACTIVATE_MOBILE_TOKEN = 'activateMobileToken',
  LOGIN = 'login'
}
