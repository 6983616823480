import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationPolicy } from '../../authentication-form/authentication.model';
import { AutoUnsubscribe } from '../../shared/auto.unsubscribe.decorator';

@Component({
  selector: 'wg-forget-authenticator-alert',
  templateUrl: './forget-authenticator-alert.component.html',
  styleUrls: ['./forget-authenticator-alert.component.scss']
})
@AutoUnsubscribe()
export class ForgetAuthenticatorAlertComponent {

  @Input() authenticationPolicy: AuthenticationPolicy;

  constructor(
    private translateService: TranslateService
  ) { }

  getForgottenRemainingTime(): { remainingTime: string } {
    const seconds = this.authenticationPolicy.authenticatorForgottenRemainingTimeInSeconds;
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    let remainingTime = '';
    if (hours > 0) {
      const hourTranslated = this.translateService.instant('auth_sso_login_forgot_token_activated_alert_hours', hours.toString());
      remainingTime = hours.toString() + hourTranslated;
    }
    if (minutes > 0) {
      const minuteTranslated = this.translateService.instant('auth_sso_login_forgot_token_activated_alert_minutes');
      remainingTime += ' ' + minutes.toString() + minuteTranslated;
    }
    if (minutes === 0) {
      const secondTranslated = this.translateService.instant('auth_sso_login_forgot_token_activated_alert_seconds');
      remainingTime += ' ' + seconds + secondTranslated;
    }
    return { remainingTime };
  }
}
