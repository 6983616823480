import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { BrowserService, DEFAULT_IMAGE, PATH } from '../shared';
import { SamlApplication } from '../applications/applications.model';
import { Authentication, AuthenticationPolicy, IDP_LOGIN } from '../authentication-form/authentication.model';
import { Branding } from '../branding/branding.model';
import { BrandingService } from '../branding/branding.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private brandingService: BrandingService,
    private browserService: BrowserService,
    private router: Router
  ) { }

  hasToUpgradeSession(authenticationPolicy: AuthenticationPolicy) {
    return authenticationPolicy?.isUpgrade;
  }

  upgradeSession(
    authentication: Authentication,
    authenticationPolicy: AuthenticationPolicy,
    samlApplication?: SamlApplication
  ) {
    if (this.hasToLoadBranding(samlApplication)) {
      this.loadBrandingLogo(authentication?.accountId)
        .subscribe(
          (logo: string) => this.handleUpgradeSession(authentication, authenticationPolicy, samlApplication, logo)
        );
    } else {
      this.handleUpgradeSession(authentication, authenticationPolicy, samlApplication);
    }
  }

  private hasToLoadBranding(samlApplication: SamlApplication): boolean {
    return (!samlApplication || (samlApplication.isTypeOthers && !samlApplication.hasImage));
  }

  private loadBrandingLogo(accountId: string): Observable<string> {
    this.brandingService.loadBranding(accountId, true);
    return this.brandingService.branding
      .pipe(
        map((branding: Branding) => branding.smallImageUrl),
        take(1)
      );
  }

  private handleUpgradeSession(
    authentication: Authentication,
    authenticationPolicy: AuthenticationPolicy,
    samlApplication: SamlApplication,
    logo: string = DEFAULT_IMAGE.HEADER
  ) {
    const spName = samlApplication ? samlApplication.name : IDP_LOGIN;
    const isSpInitiated = Boolean(samlApplication);
    const logoImage = samlApplication ? samlApplication.getImage(authentication.accountId, logo) : logo;

    this.browserService.saveServiceProviderLogo(spName, logoImage);
    this.browserService.saveAuthentication(spName, authentication);
    this.browserService.saveAuthenticationPolicy(spName, authenticationPolicy);

    const route = this.getRoute(authenticationPolicy, isSpInitiated);
    const navigationExtras = this.getNavigationExtras(spName, isSpInitiated);

    this.router.navigate(route, navigationExtras);
  }

  private getRoute({ otp, password, qrCode, push }: AuthenticationPolicy, isSpInitiated: boolean): string[] {
    const accountAlias = this.browserService.getAuthenticationCookies('accountAlias');

    const isAuthenticationByOtp: boolean = (otp && password && !qrCode && !push) || (otp && !password && !qrCode && !push);
    const path = isAuthenticationByOtp ? PATH.OTP : PATH.VALIDATE;

    return isSpInitiated ? [PATH.SP_INITIATED, path] : [accountAlias, path];
  }

  private getNavigationExtras(spName: string, isSpInitiated: boolean): NavigationExtras {
    const navigationExtras: NavigationExtras = { queryParams: { serviceProvider: spName } };

    if (!isSpInitiated) {
      navigationExtras.relativeTo = this.activatedRoute;
    }

    return navigationExtras;
  }
}
