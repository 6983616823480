import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { AutoUnsubscribe, ErrorHandlerService, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS } from '../../shared';
import { QrCodeActivationComponent } from '../qrcode-activation/qrcode-activation.component';

@Component({
  selector: 'wg-ssp-mobile-token-activation-dialog',
  templateUrl: './mobile-token-activation-dialog.component.html'
})
@AutoUnsubscribe()
export class MobileTokenActivationDialogComponent {

  @Input() mustOpenMobileTokenActivationDialog: boolean;
  @Input() accountId: string;
  @Input() username: string;
  @Output() closeMobileTokenActivationDialog = new EventEmitter();

  @ViewChild(QrCodeActivationComponent)
  qrCodeActivationComponent: QrCodeActivationComponent;

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  title = this.messageTranslateKeys.mobile_token_activation_title;
  message = this.messageTranslateKeys.mobile_token_activation_usage;

  errorMessage: string;
  isUserAuthenticated = false;

  constructor(
    private errorHandlerService: ErrorHandlerService
  ) { }

  closeDialog() {
    this.mustOpenMobileTokenActivationDialog = false;
    this.isUserAuthenticated = false;

    if (this.qrCodeActivationComponent) {
      this.qrCodeActivationComponent.cleanVariables();
    }

    this.closeMobileTokenActivationDialog.emit();
  }

  onPasswordAuthenticated(error: HttpErrorResponse) {
    if (error) {
      this.errorHandler(ERROR_PREFIX.ssp_password_authentication, error);
    } else {
      this.isUserAuthenticated = true;

      if (this.qrCodeActivationComponent) {
        this.qrCodeActivationComponent.getQrCodeData();
      }
    }
  }

  private errorHandler(requestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(
      requestType,
      httpErrorResponse
    );
  }

}
