import { getEnv, getRegion } from '@watchguard/wg-utils';

export const SESSION_EXPIRATION_TIME_IN_MINUTES = 480;

let branch = getEnv();

// For production env the getEnv method will return undefined
branch = branch ? branch + '.' : '';

export let REGION: string = getRegion();

// TODO: Remove corporate-applications validation when it goes to dev
if (location.hostname.startsWith('fb-') || location.hostname.indexOf('corporate-applications') === 0) {
    branch = 'dev.';
    REGION = 'usa';
}

export const BRANCH = branch;

export const BASE_PASSWORD_URL = `https://${branch}auth-management.authpoint.${REGION}.cloud.watchguard.com/password`;
export const BASE_URL = `https://${branch}sp.authpoint.${REGION}.cloud.watchguard.com/saml`;
export const URL_ACCOUNT_INFO = `https://${branch}account.authpoint.watchguard.com/info/:accountAlias`;
export const BASE_ACTIVATION = `https://${branch}dskpp.authpoint.${REGION}.cloud.watchguard.com/watchguard/aaas/dskpp/activation`;
export const URL_BRANDING = `https://${branch}auth-management.authpoint.${REGION}.cloud.watchguard.com/branding/v1/accounts/:accountId`;
export const BASE_URL_SSP = `https://${branch}selfserviceportal.authpoint.${REGION}.cloud.watchguard.com/self-service-portal/v1/accounts/:accountId`;
export const BASE_URL_VAULT_SESSION = `https://${branch}vault-session.authpoint.${REGION}.cloud.watchguard.com/authpoint-vault-session/v1/accounts/:accountId/vaultsessions/corporates`;
