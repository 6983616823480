import { AfterViewInit, Component, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { SamlFormService, SamlResponse } from './saml-form.service';

@Component({
  selector: 'wg-saml-form-component',
  template: `
        <form [target]="target" [hidden]="true" #samlSubmitForm action="{{ assertionConsumerServiceUrl }}" method="POST">
            <input name="SAMLResponse" type="hidden" [value]="response" />
            <input *ngIf="isRelayState" name="RelayState" type="hidden" [value]="relayState" />
        </form>`
})
export class SamlFormComponent implements AfterViewInit {
  @ViewChild('samlSubmitForm') samlSubmitForm: ElementRef<HTMLFormElement>;

  relayState: string;
  response: string;
  assertionConsumerServiceUrl: string;
  isRelayState: boolean;
  target: string;

  constructor(
    public samlFormService: SamlFormService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.samlFormService.samlSubmit.subscribe((samlResponse: SamlResponse) => this.submitForm(samlResponse));
  }

  submitForm(samlResponse: SamlResponse) {
    this.assertionConsumerServiceUrl = this.getAssertionConsumerServiceUrl(samlResponse.assertionConsumerServiceUrl);

    // INFO: This response is injected on same variable on login and logout because the
    // Service Provide wait a response with name SAMLResponse
    this.target = samlResponse.isIdpInitiated ? '_blank' : '_self';
    if (samlResponse.logoutResponse) {
      this.response = samlResponse.logoutResponse;
    } else {
      this.response = samlResponse.samlResponse;
      if (samlResponse.relayState) {
        this.isRelayState = true;
        this.relayState = samlResponse.relayState;
      }
    }
    this.changeDetectorRef.detectChanges();
    this.samlSubmitForm.nativeElement.submit();
  }

  private getAssertionConsumerServiceUrl(assertionConsumerServiceUrl: string): string {
    return assertionConsumerServiceUrl.startsWith('http')
      ? assertionConsumerServiceUrl
      : 'http://' + assertionConsumerServiceUrl;
  }
}
