import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Authentication, GeolocationData } from '../authentication-form/authentication.model';
import { PendingTokenActivationRequest } from '../models/pending-token-activation';

@Injectable({ providedIn: 'root' })
export class GeolocationService {

  getGeolocation(timeout: number = 7000): Observable<GeolocationData> {
    const options = {
      enableHighAccuracy: true,
    };

    let geolocationData: GeolocationData = null;

    return new Observable((observer) => {
      let watchId: number;
      let hadAnswer = false;

      if ('geolocation' in navigator) {
        watchId = navigator.geolocation.watchPosition(
          (position) => {
            geolocationData = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            };
            hadAnswer = true;
            this.sendGeolocation(observer, geolocationData, watchId);
          },
          (error) => {
            hadAnswer = true;
            this.sendGeolocation(observer, geolocationData, watchId);
          },
          options
        );
      } else {
        observer.next(geolocationData);
        observer.complete();
      }

      if ('geolocation' in navigator) {
        setTimeout(() => {
          if (!hadAnswer) {
            this.sendGeolocation(observer, geolocationData, watchId);
          }
        }, timeout);
      }

      return { unsubscribe() { } };
    });
  }

  validateGeolocation(data: Authentication | PendingTokenActivationRequest,
    timeout?: number): Observable<Authentication | PendingTokenActivationRequest> {

    if (data.geolocationData) {
      return of(data);
    }

    return this.getGeolocation(timeout)
      .pipe(
        switchMap((geolocationData: GeolocationData) => {
          data.geolocationData = geolocationData;
          return of(data);
        })
      );
  }

  private sendGeolocation(
    observer: Observer<GeolocationData>,
    geolocationData: GeolocationData,
    watchId: number
  ) {
    observer.next(geolocationData);
    observer.complete();
    navigator.geolocation.clearWatch(watchId);
  }
}
