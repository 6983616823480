/**
 * Decorator to unsubscribe all subscription that were created and should be
 * unsubscribed once the component is destroyed
 *
 * It helps to save memory
 *
 * @param blacklist<Array<string>> property that should not be unsubscribed
 */
export function AutoUnsubscribe(blacklist = []) {
    return function (constructor) {
        const ngOnDestroyOriginal = constructor.prototype.ngOnDestroy;

        constructor.prototype.ngOnDestroy = function () {
            Object.keys(this)
                .filter((prop: string) => this[prop] && typeof this[prop].unsubscribe === 'function' && !blacklist.includes(this[prop]))
                .forEach((prop: string) => this[prop].unsubscribe());

            if (typeof ngOnDestroyOriginal === 'function') {
                ngOnDestroyOriginal.apply(this, arguments);
            }
        };
    };
}
