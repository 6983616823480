import { Routes, RouterModule } from '@angular/router';

import { ErrorValidateComponent } from './error-validate.component';
import { PATH } from '../shared/constants';

const APP_ROUTES: Routes = [
  {
    path: PATH.ERROR,
    component: ErrorValidateComponent
  }
];

export const ErrorValidateRoute = RouterModule.forChild(APP_ROUTES);
