import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { LoaderService } from '@watchguard/wg-loader/';

import { BASE_ACTIVATION } from '../config/app-settings';

export class Activation {
  qrCodeImage: string;
  mobileActivationLink: string;
}

@Injectable()
export class ActivationService implements Resolve<Activation> {

  constructor(
    private http: HttpClient,
    private loaderService: LoaderService) {
  }

  getActivation(id: string, data: string): Observable<Activation> {
    const params: HttpParams = new HttpParams()
      .set('id', id)
      .set('data', data);


    this.loaderService.showLoading();
    return this.http
      .get(BASE_ACTIVATION, { observe: 'response', params: params })
      .pipe(
        map((res: any) => {
          if (res.status === 200) {
            return res.body;
          } else {
            return throwError(res);
          }
        }),
        catchError(error => of({ error: error })),
        finalize(() => this.loaderService.hideLoading())
      );
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Activation> {
    return this.getActivation(route.queryParamMap.get('id'), route.queryParamMap.get('data'));
  }
}
