import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorNotificationService {

  private errorEmitter = new BehaviorSubject<HttpErrorResponse>(null);

  constructor(
    private router: Router
  ) {
    this.observeRouter();
  }

  emitError(error?: HttpErrorResponse) {
    this.errorEmitter.next(error);
  }

  getError(): Observable<any> {
    return this.errorEmitter
      .asObservable()
      .pipe(
        map(this.mapErrorFields),
        filter(this.skipError),
        debounceTime(100)
      );
  }

  mapErrorFields(httpError: HttpErrorResponse): any {
    let errorsCode = '';
    let requestId = '';

    if (httpError && httpError.error && httpError.error.errors) {
      errorsCode = httpError.error.errors.map(error => error.code);
      errorsCode = Array.from(new Set(errorsCode)).join(', ');
    }

    if (httpError && httpError.headers) {
      requestId = httpError.headers.get('request-id');
    }

    return { requestId, errorsCode };
  }

  newRequest(request: HttpRequest<any>) {
    if (!request.url.endsWith('.json')) {
      this.errorEmitter.next(null);
    }
  }

  private skipError({ errorsCode }: any): boolean {
    return !errorsCode.includes('201085005');
  }

  private observeRouter() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        filter((event: NavigationStart) => !event.url.includes('/error'))
      )
      .subscribe(() => this.errorEmitter.next(null));
  }
}
