import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { AuthenticateUserPasswordRequest, AuthenticateUserPasswordResponse } from '../ssp-token.model';
import { SspTokenService } from '../ssp-token.service';
import { AutoUnsubscribe, ErrorHandlerService, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS } from '../../shared';

@Component({
  selector: 'wg-ssp-password-authentication',
  templateUrl: './password-authentication.component.html',
  styleUrls: ['password-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class PasswordAuthenticationComponent {

  @Input() passwordAuthenticationDialog: boolean;
  @Input() title: string;
  @Input() message: string;
  @Input() accountId: string;
  @Input() username: string;
  @Output() closePasswordAuthenticationDialog = new EventEmitter();
  @Output() onPasswordAuthenticated = new EventEmitter<HttpErrorResponse>();

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;

  password: string = null;
  errorMessage: string = null;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private errorHandlerService: ErrorHandlerService,
    private sspTokenService: SspTokenService
  ) { }

  closeDialog() {
    this.cleanVariables();
    this.closePasswordAuthenticationDialog.emit();
  }

  authenticateUserPassword(username?: string, password?: string) {
    if (username) {
      this.username = username;
    }

    if (password) {
      this.password = password;
    }

    this.sspTokenService.authenticateUserPassword(this.accountId, this.authenticateUserPasswordRequest)
      .subscribe(
        (response: AuthenticateUserPasswordResponse) => {
          this.sspTokenService.securityContextId = response.securityContextId;
          this.cleanVariables();
          this.onPasswordAuthenticated.emit();
        },
        (error: HttpErrorResponse) => {
          if (this.passwordAuthenticationDialog) {
            this.errorHandler(ERROR_PREFIX.ssp_password_authentication, error);
          } else {
            this.onPasswordAuthenticated.emit(error);
          }
        }
      );
  }

  cleanErrorMessage() {
    this.errorMessage = null;
  }

  private get authenticateUserPasswordRequest(): AuthenticateUserPasswordRequest {
    return {
      login: this.username,
      password: this.password,
      challenge: this.sspTokenService.challenge
    };
  }

  private errorHandler(resquestType: string, httpErrorResponse: HttpErrorResponse) {
    this.errorMessage = this.errorHandlerService.getMessageError(resquestType, httpErrorResponse);
    this.changeDetector.detectChanges();
  }

  private cleanVariables() {
    this.passwordAuthenticationDialog = false;
    this.password = null;
    this.errorMessage = null;
  }

}
