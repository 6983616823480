import { RouterModule, Routes } from '@angular/router';

import { SPAuthenticationComponent } from '../service-provider-authentication/sp-authentication.component';
import { PATH } from '../shared/';
import { SPAuthenticationService } from './sp-authentication.service';

const APP_ROUTES: Routes = [
    {
        path: PATH.SAML,
        component: SPAuthenticationComponent,
        resolve: {
          authentication: SPAuthenticationService
        }
    }
];

export const RouteSPAuthentication = RouterModule.forChild(APP_ROUTES);
