import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SPAuthenticationComponent } from './sp-authentication.component';
import { RouteSPAuthentication } from './sp-authentication-route.module';
import { SPAuthenticationService } from './sp-authentication.service';
import { SharedModule } from '../shared/shared.module';
import { SessionModule } from '../session/session.module';

@NgModule({
  imports: [
    RouteSPAuthentication,
    CommonModule,
    FormsModule,
    SharedModule,
    SessionModule
    ],
  declarations: [
    SPAuthenticationComponent
  ],
  providers: [
    SPAuthenticationService
  ]
})
export class SPAuthenticationModule { }
