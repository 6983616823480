import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Branding } from '../../branding/branding.model';
import { BrandingService } from '../../branding/branding.service';
import { DEFAULT_IMAGE } from '../../shared';
import { MESSAGE_TRANSLATE_KEYS } from '../../shared/ui-strings';

import { Header } from './header.model';
import { HeaderService } from './header.service';

@Component({
  selector: 'wg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  logo: string;
  title: string;
  subtitle: string;

  constructor(
    private headerService: HeaderService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private brandingService: BrandingService
  ) { }

  ngOnInit() {
    this.getCurrentActivatedRoute(this.activatedRoute).data
      .subscribe((data) => {
        this.title = data.title;
        this.logo = data.serviceProviderLogo;
      });
    this.headerService.header.subscribe((header: Header) => this.buildHeader(header));
    this.brandingService.branding.subscribe((branding: Branding) => {
      this.logo = branding.smallImageUrl;
    });
  }

  loadDefaultImage() {
    this.logo = DEFAULT_IMAGE.HEADER;
  }

  verifyTypeAccess(): string {
    if (this.subtitle) {
      if (this.subtitle.indexOf('@') === -1) {
        return this.translate.instant(this.messageTranslateKeys.user_name);
      } else {
        return this.translate.instant(this.messageTranslateKeys.email);
      }
    }
  }

  private getCurrentActivatedRoute(activatedRoute: ActivatedRoute) {
    let route: ActivatedRoute = activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private buildHeader(header: Header) {
    if (typeof header?.serviceProviderLogo === 'string') {
      this.logo = header.serviceProviderLogo;
    }
    this.title = header.title;
    this.subtitle = header.subtitle;
  }
}
