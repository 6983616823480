import { DEFAULT_IMAGE } from '../shared';

export class Branding {
  mediumImageUrl: string;
  backgroundImageUrl: string;
  smallImageUrl: string;
  thumbnailImageUrl: string;
  expiration: number;

  constructor(source?: Partial<Branding>) {
    this.smallImageUrl = DEFAULT_IMAGE.HEADER;
    this.backgroundImageUrl = DEFAULT_IMAGE.BACKGROUND;
    this.expiration = Date.now() + 28800000;

    Object.assign(this, source);
  }
}
