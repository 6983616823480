import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';
import { BASE_URL } from '../../config/app-settings';
import { BrowserService } from '../../shared/browser.service';
import { Authentication } from '../authentication.model';
import { GeolocationService } from '../../shared/geolocation.service';

@Injectable()
export class ValidateService extends AuthenticationService {

  pushDefaultTimeout = 1;

  constructor(
    public router: Router,
    public http: HttpClient,
    public browserService: BrowserService,
    public geolocationService: GeolocationService
  ) {
    super(router, http, browserService, geolocationService);
  }

  pushResult(authentication: Authentication, isUpgrade?: boolean): Observable<any> {
    let observable: Observable<any>;
    if (authentication.saml && authentication.saml.selectedSp) {
      observable = isUpgrade ? this.pushResultAuthenticateUpgrade(authentication) : this.pushResultAuthenticateMFAByPass(authentication);
    } else {
      observable = this.pushResultAuthenticate(authentication);
    }
    return observable;
  }

  private pushResultAuthenticate(authentication: Authentication) {
    return this.geolocationService.validateGeolocation(authentication, this.pushDefaultTimeout)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/authentication/push/result`, auth))
      );
  }


  private pushResultAuthenticateUpgrade(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication, this.pushDefaultTimeout)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authenticationUpgrade/push/result`, auth))
      );
  }

  private pushResultAuthenticateMFAByPass(authentication: Authentication): Observable<any> {
    return this.geolocationService.validateGeolocation(authentication, this.pushDefaultTimeout)
      .pipe(
        switchMap((auth: Authentication) => this.http.post(`${BASE_URL}/sso/serviceProvider/authentication/push/result`, auth))
      );
  }
}
