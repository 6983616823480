import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { URL_BRANDING } from '../config/app-settings';
import { BrowserService } from '../shared';
import { Branding } from './branding.model';

@Injectable()
export class BrandingService {
  private brandingSubject: ReplaySubject<Branding> = new ReplaySubject<Branding>();
  branding: Observable<Branding> = this.brandingSubject.asObservable();

  constructor(
    private browserService: BrowserService,
    private http: HttpClient
  ) { }

  loadBranding(accountId: string, cached?: boolean): void {
    if (!accountId) {
      this.setBranding(new Branding({ expiration: Date.now() }));
      return;
    }
    const branding = this.browserService.getItemLocalStorage(`branding-${accountId}`);
    if (cached && branding && branding.expiration > Date.now()) {
      this.setBranding(branding);
    } else {
      this.fetchBranding(accountId)
        .subscribe((brandingResponse: Branding) => this.saveBranding(brandingResponse, accountId));
    }
  }

  clearBranding(accountId: string) {
    this.browserService.deleteItemLocalStorage(`branding-${accountId}`);
  }

  loadImage(imageUrl: string): Observable<Event> {
    const image: HTMLImageElement = new Image();
    image.src = imageUrl;
    return merge(
      fromEvent(image, 'load'),
      fromEvent(image, 'error').pipe(mergeMap(() => throwError('Invalid image')))
    );
  }

  private setBranding(branding: Branding) {
    this.brandingSubject.next(branding);
  }

  private fetchBranding(accountId: string): Observable<Branding> {
    return this.http
      .get<Branding>(URL_BRANDING.replace(':accountId', accountId))
      .pipe(
        map((branding: Branding) => new Branding(branding)),
        catchError(() => of(new Branding({ expiration: Date.now() })))
      );
  }

  private saveBranding(branding: Branding, accountId: string) {
    this.setBranding(branding);
    this.browserService.setItemLocalStorage(`branding-${accountId}`, branding);
  }
}
