import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BASE_URL } from '../../config/app-settings';
import { PendingTokenActivationRequest } from '../../models/pending-token-activation';
import { GeolocationService } from '../../shared/geolocation.service';

@Injectable()
export class PendingTokenActivationService {

  constructor(
    private http: HttpClient,
    private geolocationService: GeolocationService
  ) { }

  generateQrCodeActivationData(pendingTokenActivationRequest: PendingTokenActivationRequest): Observable<any> {
    return this.geolocationService.validateGeolocation(pendingTokenActivationRequest)
      .pipe(
        switchMap((pendingToken: PendingTokenActivationRequest) => this.http.post(`${BASE_URL}/sso/authentication/authenticator/activationData`, pendingToken)),
      );
  }

}
