import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationComponent } from '../authentication-form/authentication.component';
import { IDP_LOGIN } from '../authentication-form/authentication.model';
import { HeaderService } from '../login/header/header.service';
import { BrowserService, ErrorHandlerService, ERROR_PREFIX, MESSAGE_TRANSLATE_KEYS, PATH, SamlFormService } from '../shared';
import { ApplicationListService } from '../applications/applications-list/application-list.service';
import { AuthenticateUserPasswordRequest, AuthenticateUserPasswordResponse, AuthMethod } from './ssp-token.model';
import { SspTokenService } from './ssp-token.service';
import { ErrorMessages } from './ssp-token.constants';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@watchguard/wg-loader';

@Component({
  templateUrl: 'ssp-token.component.html'
})
export class SspTokenComponent extends AuthenticationComponent implements OnInit {

  messageTranslateKeys = MESSAGE_TRANSLATE_KEYS;
  chosenAuthMethod: AuthMethod;
  isOnlyPassword = false;
  password: string;

  constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    errorHandleService: ErrorHandlerService,
    headerService: HeaderService,
    browserService: BrowserService,
    samlFormService: SamlFormService,
    applicationListService: ApplicationListService,
    private sspTokenService: SspTokenService,
    private loaderService: LoaderService,
  ) {
    super(activatedRoute, router, errorHandleService, headerService, browserService, samlFormService, applicationListService);
  }

  ngOnInit() {
    this.authentication = this.browserService.getItemLocalStorage(`${IDP_LOGIN}_authentication`);
    this.authenticationPolicy = this.browserService.getItemLocalStorage(`${IDP_LOGIN}_authenticationPolicy`);
    const serviceProvider = this.browserService.getItemLocalStorage(`${IDP_LOGIN}_serviceProviderLogo`);
    this.headerService.headerSubmit({
      title: this.messageTranslateKeys.choose_one_option_bellow,
      serviceProviderLogo: serviceProvider?.serviceProviderLogo,
      subtitle: this.authentication.user.username,
    });

    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (queryParams.error) {
      this.showError(queryParams.error);
    }

    this.isOnlyPassword = this.isOnlyPasswordAuthOption();

  }

  isOnlyPasswordAuthOption() {
    return this.authenticationPolicy.password &&
      !this.authenticationPolicy.otp &&
      !this.authenticationPolicy.push &&
      !this.authenticationPolicy.qrCode;
  }

  onSubmit(form: any) {
    if (form.valid) {
      const options = {
        [AuthMethod.LOGIN]: () => this.passwordLogin(),
        [AuthMethod.ACTIVATE_MOBILE_TOKEN]: () => this.authenticatePassword(PATH.SSP_MOBILE_TOKEN_ACTIVATION),
        [AuthMethod.ACTIVATE_HARDWARE_TOKEN]: () => this.authenticatePassword(PATH.SSP_HARDWARE_TOKEN_ACTIVATION)
      };
      options[this.chosenAuthMethod]();
    }
  }

  authenticatePassword(route: string) {
    this.sspTokenService.authenticateUserPassword(this.authentication.accountId, this.authenticateUserPasswordRequest)
      .subscribe(
        (response: AuthenticateUserPasswordResponse) => {
          this.sspTokenService.securityContextId = response.securityContextId;
          this.router.navigate(['..', route], { relativeTo: this.activatedRoute });
        },
        (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.ssp_password_authentication, error)
      );
  }

  passwordLogin() {
    this.loaderService.showLoading();
    this.setAuthenticationUser(false, this.password, null, false);
    this.sspTokenService.login(this.authentication).pipe(
      finalize(() => this.loaderService.hideLoading())
    )
      .subscribe(
        (response: any) => this.handleLoginSuccess(response),
        (error: HttpErrorResponse) => this.errorHandle(ERROR_PREFIX.authentication_password, error)
      );
  }

  private get authenticateUserPasswordRequest(): AuthenticateUserPasswordRequest {
    return {
      login: this.authentication.user.username,
      password: this.password,
      challenge: this.sspTokenService.challenge
    };
  }

  private showError(error: string) {
    const mainMessageKey = ErrorMessages[error];
    if (mainMessageKey) {
      this.errorHandle(null, { error: { mainMessageKey: mainMessageKey } } as HttpErrorResponse);
    }
  }
}
