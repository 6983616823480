import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { Authentication } from '../authentication-form/authentication.model';
import { BASE_URL } from '../config/app-settings';
import { BrowserService } from '../shared/browser.service';
import { ApplicationListService } from './applications-list/application-list.service';
import { Application } from './applications.model';
import { BrandingService } from '../branding/branding.service';

@Injectable()
export class ApplicationsService implements Resolve<Application[]> {
  private accountAlias: string;
  private authentication: Authentication;

  constructor(
    private applicationListService: ApplicationListService,
    private brandingService: BrandingService,
    private browserService: BrowserService,
    private http: HttpClient,
    private router: Router
  ) { }

  setAuthentication(authentication: Authentication) {
    this.authentication = authentication;
  }

  getAuthentication(): Authentication {
    return this.authentication;
  }

  setAccountAlias(accountAlias: string) {
    this.accountAlias = accountAlias;
  }

  getAccountAlias() {
    return this.accountAlias;
  }

  isLogged(accountAlias: string): boolean {
    const accountAliasSavedCookie: string = this.browserService.getAuthenticationCookies('accountAlias');
    const authenticationString: string = this.browserService.getAuthenticationCookies('authentication');
    if (authenticationString && accountAlias === accountAliasSavedCookie) {
      this.setAuthentication(JSON.parse(authenticationString));
      this.setAccountAlias(accountAlias);
      return true;
    }

    let accountId: string;
    if (authenticationString) {
      const authentication: Authentication = JSON.parse(authenticationString);
      accountId = authentication.accountId;
      this.logout(authentication).subscribe();
    }
    this.cleanCookieAndRedirecToInitialPage(accountAlias, accountId);
  }

  cleanCookieAndRedirecToInitialPage(accountAlias: string, accountId: string) {
    this.browserService.deleteCookies();
    const queryParams = (accountId) ? { accountId } : {};
    this.router.navigate([accountAlias], { queryParams });
  }

  logout(authentication: Authentication): Observable<any> {
    delete authentication.saml;
    delete authentication.user.authentication;
    return this.http
      .post(`${BASE_URL}/slo/logout`, authentication)
      .pipe(tap(() => this.brandingService.clearBranding(authentication.accountId)));
  }

  resolve({ parent }: ActivatedRouteSnapshot): Observable<Application[]> {
    if (this.isLogged(parent.params.accountAlias)) {
      return this.applicationListService.applications
        .pipe(
          tap(() => this.brandingService.loadBranding(this.authentication.accountId, true)),
          first()
        );
    }
  }
}
