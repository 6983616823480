import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { WgOnlyNumberModule } from '@watchguard/wg-directives';

import { BrandingService } from '../branding/branding.service';
import { HttpErrorNotificationModule } from '../http-error-notification/http-error-notification.module';
import { LoginRoute } from '../login/login.route';
import { PasswordModule } from '../password/password.module';
import { SspTokenModule } from '../self-service-portal/ssp-token.module';
import { HeadersHandlerInterceptor } from '../shared/headers.interceptor';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationModule } from './../authentication-form/authentication.module';
import { BackPageComponent } from './back-page/back-page.component';
import { ForgetAuthenticatorService } from './forget-authenticator/forget-authenticator.service';
import { HeaderComponent } from './header/header.component';
import { HeaderService } from './header/header.service';
import { LoginComponent } from './login.component';

@NgModule({
  imports: [
    LoginRoute,
    AuthenticationModule,
    PasswordModule,
    SharedModule,
    HttpErrorNotificationModule,
    SspTokenModule,
    WgOnlyNumberModule
  ],
  declarations: [
    LoginComponent,
    BackPageComponent,
    HeaderComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersHandlerInterceptor,
      multi: true
    },
    BrandingService,
    HeaderService,
    ForgetAuthenticatorService
  ]
})
export class LoginModule { }
