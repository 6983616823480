export enum PATH {
  VALIDATE = 'validate',
  QR_CODE = 'qrcode',
  OTP = 'otp',
  REQUEST_FORGOTTEN = 'forgotten-token',
  FORGOTTEN = 'authenticator-forgotten',
  SERVICE_PROVIDER_LIST = 'resources',
  SAML = 'saml',
  PASSWORD = 'password',
  SUCESSFULLY_MESSAGE = 'success-message',
  MAIN_ENDPOINT_PATH_PARAMS = ':accountAlias',
  SP_INITIATED = 'spInitiated',
  ERROR = 'error',
  ACTIVATION = 'activation',
  SSP_TOKEN = 'token',
  SSP_HARDWARE_TOKEN_ACTIVATION = 'hardware-token-activation',
  SSP_MOBILE_TOKEN_ACTIVATION = 'mobile-token-activation'
}

export enum ERROR_CODE {
  UNAUTHORIZED_RESOURCE = '201085008',
  INVALID_ACCOUNT = '201085005'
}

export enum DEFAULT_IMAGE {
  HEADER = 'assets/logos/OTHERS.png',
  BACKGROUND = 'assets/image/image_mean.jpg',
  ERROR = 'assets/image/error.png'
}

export enum SAML_APPLICATION_TYPE {
  RD_WEB = 'WG_RDWEB',
  OTHERS = 'OTHERS'
}
