import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { HttpErrorNotificationService } from '../http-error-notification/http-error-notification.service';
import { MESSAGE_TRANSLATE_KEYS } from './ui-strings';

@Injectable()
export class ErrorHandlerService {

  constructor(
    private translateService: TranslateService,
    private httpErrorNotificationService: HttpErrorNotificationService
  ) { }

  getMessageError(requestType: string, { error, status }: HttpErrorResponse): string {

    const errorMessage = error && error.mainMessageKey
      || this.getHttpErrorMessage(requestType, status)
      || MESSAGE_TRANSLATE_KEYS.defaultErrorMessage;

    return this.translateService.instant(errorMessage);
  }

  verifiyErrorCode(errorsCodeToVerify: string[], errorResponse: HttpErrorResponse): boolean {
    const { errorsCode = '' } = this.httpErrorNotificationService.mapErrorFields(errorResponse);
    return errorsCodeToVerify.some(error => errorsCode.includes(error));
  }

  getMessageErrorWithCode(requestType: string, errorResponse: HttpErrorResponse): string {
    const { errorsCode = '', requestId = '' } = this.httpErrorNotificationService.mapErrorFields(errorResponse) || {};
    const errorMessage = this.getMessageError(requestType, errorResponse);

    let errorCodeMessage = this.translateService.instant(MESSAGE_TRANSLATE_KEYS.error_code);
    let requestIdMessage = this.translateService.instant(MESSAGE_TRANSLATE_KEYS.request_id);

    errorCodeMessage = errorsCode && `${errorCodeMessage}: ${errorsCode}.`;
    requestIdMessage = requestId && `${requestIdMessage}: ${requestId}.`;

    return `${errorMessage} ${errorCodeMessage} ${requestIdMessage}`;
  }

  private getHttpErrorMessage(requestType: string, status: number): string {
    const translationKey = requestType + status;
    const translatedMessage = this.translateService.instant(translationKey);
    const hasTranslation = translatedMessage !== translationKey;

    return hasTranslation
      ? translatedMessage
      : undefined;
  }
}
